<template>
  <VField v-slot="{ field, meta }" :name="name ?? 'undefined'" :rules="rules">
    <select
      v-bind="field"
      v-model="currentValue"
      class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-0 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      :class="[
        {
          'border-green-300 focus:border-green-500 focus:ring-green-500':
            meta.valid && meta.touched && rules,
          'border-red-300 focus:border-red-500  focus:ring-red-500':
            !meta.valid && meta.touched && rules
        },
        selectClass
      ]"
      :rules="rules"
      @input="onInput"
    >
      <option v-if="placeholder" value="" selected hidden>
        {{ placeholder }}
      </option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :selected="option.value === selectedValue"
      >
        {{ option.text }}
      </option>
    </select>
    <VErrorMessage
      v-if="showErrorMessage"
      :name="name ?? 'undefined'"
      as="p"
      class="text-sm italic text-red-500"
    />
  </VField>
</template>

<script lang="ts" setup>
type PropsSelect = {
  name?: string
  rules?: string
  options: any[]
  placeholder?: string
  selectedValue?: string | number | boolean
  showErrorMessage?: boolean
  selectClass?: string
}

const props = withDefaults(defineProps<PropsSelect>(), {
  showErrorMessage: true
})

const emit = defineEmits(['input'])

function onInput(event: Event) {
  const target = event.target as HTMLInputElement
  emit('input', target.value)
}

const currentValue = ref(props.selectedValue)

watch(
  () => props.selectedValue,
  (selectedvalue) => {
    currentValue.value = selectedvalue
  }
)
</script>
